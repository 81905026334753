import * as _getIntrinsic2 from "get-intrinsic";
var _getIntrinsic = _getIntrinsic2;
try {
  if ("default" in _getIntrinsic2) _getIntrinsic = _getIntrinsic2.default;
} catch (e) {}
import * as _callBound2 from "call-bind/callBound";
var _callBound = _callBound2;
try {
  if ("default" in _callBound2) _callBound = _callBound2.default;
} catch (e) {}
var exports = {};
var GetIntrinsic = _getIntrinsic;
var callBound = _callBound;
var $WeakSet = GetIntrinsic("%WeakSet%", true);
var $setHas = callBound("WeakSet.prototype.has", true);
if ($setHas) {
  var $mapHas = callBound("WeakMap.prototype.has", true);

  /** @type {import('.')} */
  exports = function isWeakSet(x) {
    if (!x || typeof x !== "object") {
      return false;
    }
    try {
      $setHas(x, $setHas);
      if ($mapHas) {
        try {
          $mapHas(x, $mapHas);
        } catch (e) {
          return true;
        }
      }
      // @ts-expect-error TS can't figure out that $WeakSet is always truthy here
      return x instanceof $WeakSet; // core-js workaround, pre-v3
    } catch (e) {}
    return false;
  };
} else {
  /** @type {import('.')} */
  // eslint-disable-next-line no-unused-vars
  exports = function isWeakSet(x) {
    // `WeakSet` does not exist, or does not have a `has` method
    return false;
  };
}
export default exports;